import type { useFormatMessage } from '~/components/hooks/useFormatMessage'

export enum ZodCustomErrorCodes {
	INVALID_MATCH = 'invalid_match',
	INVALID_PASSWORD = 'invalid_password',
}

export type ZodEmailSchemaMessages = {
	email?: string
	min?: string
	max?: string
	regex?: string
	required?: string
}

export interface ZodPasswordSchemaArgs {
	email?: string
	path?: string
	formatMessage: ReturnType<typeof useFormatMessage>
}

export interface ZodPasswordValidationArgs extends ZodPasswordSchemaArgs {
	password?: string
	path: string
}
