import { z } from 'zod'
import { postalCodeRegexPattern } from './i18n/postal'
import type { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { UaConfigPublicImpl } from '~/lib/client-only/ua-config'
import { validatePassword } from './validators'
import {
	ZodCustomErrorCodes,
	type ZodEmailSchemaMessages,
	type ZodPasswordValidationArgs,
} from './types/forms.interface'

/**
 * Given a form element, scrolls to put that vield into view. Often use to scroll to field
 * that fails validation.
 */
export const scrollFieldIntoView = (formElement: HTMLElement | null) => {
	if (!formElement) return
	const { top: elementTop } = formElement.getBoundingClientRect()
	const { marginTop } = getComputedStyle(formElement)
	const headerHeight = document.querySelector('header')?.offsetHeight ?? 0
	// This was accounting for this to be fixed, but it's not currently like that.
	// const summaryHeight = document.querySelector('summary')?.offsetHeight ?? 0
	const top = elementTop + window.scrollY - headerHeight - parseInt(marginTop, 10)
	window.scrollTo({ top, behavior: 'smooth' })
}

/**
 * Helper Function to validate password fields in Zod
 */
export const validateZodPassword = ({
	password,
	email,
	path,
	formatMessage,
}: ZodPasswordValidationArgs): z.ZodCustomIssue | null => {
	if (password) {
		const passwordValidation = validatePassword(password, email ?? '')

		if (passwordValidation.invalid) {
			let message = formatMessage('form-errors-password')

			if (passwordValidation.isContainEmail) {
				message = formatMessage('invalid-password-description-note')
			}

			return {
				code: z.ZodIssueCode.custom,
				params: {
					type: ZodCustomErrorCodes.INVALID_PASSWORD,
					validation: passwordValidation,
				},
				message,
				path: [path],
			}
		}
	}

	return null
}

/**
 * Helper Function to validate that two string fields have identical
 * form values.
 */
export const validateZodMatchingStringFields = ({
	value,
	comparator,
	path,
	message,
}: {
	value: string
	comparator: string
	path: string
	message: string
}): z.ZodCustomIssue | null => {
	if (value) {
		if (value !== comparator) {
			return {
				code: z.ZodIssueCode.custom,
				params: {
					type: ZodCustomErrorCodes.INVALID_MATCH,
				},
				message,
				path: [path],
			}
		}
	}

	return null
}

/**
 * Helper function to determine if an invalid form field is due to an invalid match
 * to an associated field. See 'InvalidInputs' type in useFormValidation.tsx.
 */
export const checkFieldForInvalidMatch = (fieldValidityState?: z.ZodIssue): boolean =>
	!!fieldValidityState &&
	fieldValidityState.code === z.ZodIssueCode.custom &&
	fieldValidityState.params?.type === ZodCustomErrorCodes.INVALID_MATCH

/**
 * Reusable function that can be called within a Zod .superRefine() for password fields.
 * This standardizes our validation of new passwords being set in forms.
 */
export const refineZodPassword = ({
	value,
	ctx,
	email,
	path,
	formatMessage,
}: {
	value: string
	ctx: z.RefinementCtx
	email?: string
	path?: string
	formatMessage: ReturnType<typeof useFormatMessage>
}) => {
	const passwordValidation = validateZodPassword({
		password: value,
		email: email ?? '',
		path: path ?? 'missing-path',
		formatMessage,
	})
	if (passwordValidation) {
		// By default, Zod uses the name of the current field as the path for the validation
		// error. In most cases, this is sufficient. However, to account for some cases when
		// we need to direct the path elsewhere, we can manually attach the path. Using this
		// format below achieves this task while making the function cleaner to call.
		ctx.addIssue({
			...passwordValidation,
			path: path ? [path] : undefined,
		})
	}
}

/**
 * Reusable Zod Schema for postal/zip codes
 */
export const getZodPostalSchema = (countryCode: string, formatMessage: ReturnType<typeof useFormatMessage>) => {
	const postalPattern = postalCodeRegexPattern[countryCode.toUpperCase()]
	return z
		.string({
			required_error: formatMessage('form-errors-address-postalCode'),
		})
		.min(1, formatMessage('form-errors-address-postalCode'))
		.regex(postalPattern?.pattern, formatMessage('invalid-format'))
		.min(postalPattern?.minLength ?? 5, formatMessage('invalid-format'))
		.max(postalPattern?.maxLength ?? 10, formatMessage('invalid-format'))
}

/**
 * Reusable Zod Schema for email addresses to ensure all forms have the same requirements.
 */
export const getZodEmailSchema = (
	formatMessage: ReturnType<typeof useFormatMessage>,
	messages?: ZodEmailSchemaMessages,
) =>
	z
		.string({
			required_error: formatMessage(messages?.required ? messages.required : 'form-errors-email'),
		})
		.min(1, formatMessage(messages?.min ? messages.min : 'form-errors-email'))
		.email(formatMessage(messages?.email ? messages.email : 'form-errors-email'))
		.regex(/.\w{2,}$/, formatMessage(messages?.regex ? messages.regex : 'form-errors-email'))
		.max(100, formatMessage(messages?.max ? messages.max : 'form-errors-email'))

/**
 * Reusable Zod Schema for addresses
 */
export const getZodAddressSchema = (
	formatMessage: ReturnType<typeof useFormatMessage>,
	addressValidationPattern: RegExp,
	cityValidationPattern: RegExp,
	countryCode: string,
	locale: string,
) =>
	z.object({
		firstName: z.string().min(1, formatMessage('form-errors-address-name-first')),
		lastName: z.string().min(1, formatMessage('form-errors-address-name-last')),
		address1: z
			.string()
			.min(1, formatMessage('form-errors-address-max-length'))
			.regex(addressValidationPattern, formatMessage('invalid-format'))
			.max(35, formatMessage('form-errors-address-max-length')),
		address2: z
			.string()
			.regex(addressValidationPattern, formatMessage('invalid-format'))
			.max(35, formatMessage('form-errors-address-max-length'))
			.or(z.literal(''))
			.optional(),
		city: z
			.string()
			.min(1, formatMessage('form-errors-address-city'))
			.regex(cityValidationPattern, formatMessage('invalid-format')),
		stateCode: z
			.string()
			.optional()
			.refine(
				(input) => {
					if (UaConfigPublicImpl.stateSelectionEnabled(locale)) return input && input.length > 1
					return true
				},
				{ message: formatMessage('form-errors-address-state') },
			),
		countryCode: z.string(),
		postalCode: getZodPostalSchema(countryCode, formatMessage),
		preferred: z.string().optional(),
		isDefaultBilling: z.string().optional(),
	})
