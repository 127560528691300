import { z } from 'zod'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { exposeRefTo, polyfillFormErrors } from '~/components/actions'
import { useDebouncedCallback } from '~/components/hooks/useDebouncedCallback'
import type { Maybe } from '~/graphql/generated/uacapi/type-document-node'
import { scrollFieldIntoView } from '~/lib/forms'
import isEqual from 'lodash.isequal'
import logger from '~/lib/logger'

enum SchemaTypeReferenceOptions {
	ARRAY = 'array',
	OTHER = 'other',
}

export interface InvalidInputs {
	[key: string]: {
		invalidState: z.ZodIssue
		invalid: boolean
		input: HTMLElement
	}
}

export interface InvalidInputLogMessage {
	[key: string]: {
		invalidState: z.ZodIssue
		invalid: boolean
	}
}

export interface ValidatedFormSubmit<T> {
	validated: boolean
	validityState: InvalidInputs
	dirty: boolean
	values: { [k in keyof T]: T[k] }
}

interface ZodValidationError<T> extends z.SafeParseError<z.AnyZodObject> {
	data: { [k in keyof T]: T[k] }
}

type ZodValidationResponse<T> = z.SafeParseSuccess<z.AnyZodObject> | ZodValidationError<T>

export interface FormValidationResponse<T> {
	isValid: boolean
	invalidFields: InvalidInputs
	validation: ZodValidationResponse<T>
}

export type OnFormSubmit<T> = (
	validatedSubmit: ValidatedFormSubmit<T>,
	form?: HTMLFormElement,
	e?: React.FormEvent<HTMLFormElement>,
) => void | Promise<void>

interface FormProviderProps<T> extends Omit<React.ReactElement<HTMLFormElement>, 'type' | 'props' | 'key'> {
	/** Function to call when the form is submitted, be it validated or not. */
	onSubmit: OnFormSubmit<T>
	/** Schema that defines the shape of the form object, manages the validation schema. */
	validationSchema: z.AnyZodObject
	/** Function that adds customized validation logic on current schema */
	schemaRefinement?: z.SuperRefinement<z.AnyZodObject>
	/** If an error is thrown on the form, this flag will automatically scroll
	 * the field of view to that error. */
	scrollToError?: boolean
	/** If the scrollToError flag is true, and an Element is supplied here, it
	 * will scroll to this element when an error is thrown, and not the input field. */
	scrollToEl?: HTMLElement | null | undefined
}

export interface UseFormValidation<T> {
	reset: (data?: Maybe<{ [k in keyof T]: T[k] }>, isQuiet?: boolean) => void
	getIsDirty: () => boolean
	getFormData: () => T
	validState: { isValid: boolean; hasEmptyFields: boolean }
	handleFieldFocusout: (event: React.FocusEvent<HTMLFormElement>) => void
	bindFormValidation: (reactRef: HTMLFormElement | null) => void
	runFormValidation: (isQuiet?: boolean) => FormValidationResponse<T>
	submitForm: (event?: React.FormEvent<HTMLInputElement>) => void
	handleLiveValidation: (isQuiet?: boolean) => void
}

/**
 * This formatter removes the HTMLElement input field from `InvalidInputs`, which is
 * primarily used for DD logging. We cannot stringify a JSX Element in an object,
 * and this sanitizes the `InvalidInputs` object.
 */
export function getFormValidationMessage(input: InvalidInputs): InvalidInputLogMessage {
	const inputMessageObj: InvalidInputLogMessage = {}
	Object.keys(input).forEach((key) => {
		inputMessageObj[key] = {
			invalidState: input[key].invalidState,
			invalid: input[key].invalid,
		}
	})
	return inputMessageObj
}

/**
 * Get form element with error.
 * This is necessary because not all form fields are inputs
 * e.g. Select field
 */
export const getElementWithError = (errorEl: NodeList) =>
	Array.from(errorEl).find((el) => (el as HTMLElement).id !== '' && el) as HTMLElement

/**
 * Helper function that is used to infer the type of a field in a zod schema.
 * This is necessary because we need to do some manual work on Array form fields and this
 * helps capture these fields.
 */
function inferReferenceType<T extends z.ZodTypeAny>(schema: T) {
	if (schema instanceof z.ZodArray) return SchemaTypeReferenceOptions.ARRAY
	return SchemaTypeReferenceOptions.OTHER
}

/**
 * Local helper function that takes a Zod Schema and turns it into an object reference for name key -> type.
 * This reference has two purposes: 1) it is used for validating that the form element being referenced is in
 * the form data schema (since even non-input elements can be blurred or targeted, think buttons) and 2) it
 * highlights which form fields are an array type. This is necessary because we need to do some manual work on
 * Array form fields so keeping this available improves performance of other helper functions.
 */
export const getSchemaTypeReference = (
	validationSchema: z.AnyZodObject,
): Record<string, SchemaTypeReferenceOptions> => {
	return Object.keys(validationSchema?.shape).reduce((acc, cur) => {
		return {
			...acc,
			[cur]: inferReferenceType(validationSchema.shape[cur]),
		}
	}, {})
}

/**
 * Uses a zod schema to validate form data
 */
export const validateZodSchema = <Schema extends z.ZodTypeAny>({
	schema,
	schemaRefinement,
	data,
}: {
	schema: Schema
	schemaRefinement?: z.SuperRefinement<Schema>
	data: z.infer<Schema>
}): ZodValidationResponse<z.infer<Schema>> => {
	const refinedSchema = schemaRefinement ? schema.superRefine(schemaRefinement) : schema
	const result = refinedSchema.safeParse(data) as z.infer<Schema>

	// By default, Zod does not return the form values when there is an error during
	// validation. As such, we manually attach the form values for proper error handling.
	return result.success
		? result
		: {
				...result,
				data,
		  }
}

export const useFormValidation = <FormDataType,>({
	onSubmit,
	validationSchema,
	schemaRefinement,
	scrollToError = true,
	scrollToEl,
}: FormProviderProps<FormDataType>): UseFormValidation<FormDataType> => {
	const form = useRef<HTMLFormElement>()
	const baseFieldValuesRef = useRef<Maybe<{ [k: string]: FormDataEntryValue }>>({})
	const [validState, setValidState] = useState<{ isValid: boolean; hasEmptyFields: boolean }>({
		isValid: true,
		hasEmptyFields: false,
	})
	const [shouldReset, setShouldReset] = useState<boolean>(false)
	const [liveValidation, setLiveValidation] = useState<boolean>(false)

	/**
	 * memoize this call to get it one time, since the data should not change once it initially mounts.
	 */
	const schemaReference = useMemo(() => getSchemaTypeReference(validationSchema), [validationSchema])

	/**
	 * Function that manually clears the error states off of the form inputs
	 */
	const removeValidationAnnotation = useCallback(() => {
		Object.keys(schemaReference).forEach((field) => {
			const elementField = form.current?.elements[field]
			if (!elementField) return
			if (schemaReference[field] === SchemaTypeReferenceOptions.ARRAY) {
				elementField.forEach((errorEl) => {
					errorEl.setAttribute('aria-invalid', 'false')
					const errorField = document.getElementById(`${field}-error`)
					if (errorField) errorField.textContent = ''
				})
			} else {
				let errorEl: HTMLElement | NodeList = form.current?.elements[field]
				if (errorEl instanceof NodeList) {
					errorEl = getElementWithError(errorEl)
				}
				try {
					errorEl.setAttribute('aria-invalid', 'false')
					setFieldError(errorEl, '')
				} catch {
					logger.warn('invalid validation')
				}
			}
		})
	}, [schemaReference])

	/**
	 * Runs the validator on the serialized form data. The response is then cycled through for any thrown errors
	 * and they are presented on the form based on the name supplied within the schema.
	 */
	const runFormValidation = useCallback(
		(isQuiet = false): FormValidationResponse<z.infer<typeof validationSchema>> => {
			/* ---------- Run Validation ---------- */
			const invalidFieldResponse = {} as InvalidInputs
			const values = serialize(new FormData(form.current), schemaReference)
			const validationResult = validateZodSchema({
				schema: validationSchema,
				schemaRefinement,
				data: values,
			})

			if (!isQuiet) {
				/**
				 * Clear out all errors before re-validating.
				 */
				removeValidationAnnotation()
			}

			/**
			 * Iterate over validation errors to push messages to fields
			 */
			if (!validationResult.success) {
				validationResult.error.issues.forEach((errorDetail) => {
					const errorFieldKey = errorDetail?.path[0] ?? ''
					let errorEl = form.current?.elements[errorFieldKey]

					if (!isQuiet) {
						if (errorEl && schemaReference[errorFieldKey] === SchemaTypeReferenceOptions.ARRAY) {
							errorEl.forEach((errorEl) => {
								errorEl.setAttribute('aria-invalid', 'true')
							})
							const errorField = document.getElementById(`${errorFieldKey}-error`)
							if (errorField) errorField.textContent = errorDetail.message
						} else {
							if (errorEl instanceof NodeList) {
								errorEl = getElementWithError(errorEl)
							}
							if (
								errorEl &&
								schemaReference[errorFieldKey] !== SchemaTypeReferenceOptions.ARRAY &&
								!invalidFieldResponse[errorFieldKey]
							) {
								errorEl.setAttribute('aria-invalid', 'true')
								setFieldError(errorEl, errorDetail.message)
							}
						}
					}

					invalidFieldResponse[errorFieldKey] = {
						invalid: true,
						invalidState: errorDetail,
						input: errorEl,
					}
				})

				// if the scrollToError flag was set to true, it will animate to that first thrown error position in the DOM.
				if (!isQuiet && scrollToError && !liveValidation) {
					const fields = form.current?.elements as Iterable<Element> | ArrayLike<Element>
					const firstBadField = Array.from(fields).find((f) => f.getAttribute('aria-invalid') === 'true')
					const firstBadFieldContainer = firstBadField?.closest('.form-field') as HTMLElement
					scrollFieldIntoView(scrollToEl || firstBadFieldContainer)
				}
			}

			setValidState({
				isValid: validationResult.success,
				hasEmptyFields: getContainsEmptyFields(!validationResult.success ? validationResult.error : undefined),
			})

			return {
				isValid: validationResult.success,
				invalidFields: invalidFieldResponse,
				validation: validationResult,
			}
		},
		[
			schemaReference,
			validationSchema,
			schemaRefinement,
			removeValidationAnnotation,
			scrollToError,
			liveValidation,
			scrollToEl,
		],
	)

	/**
	 * Function that resets the base model, used with detecting if the current form state is dirty vs what the initial
	 * form data content is.
	 * This should be called when the user successfully saves the data, so that any further changes are read as dirty.
	 */
	const reset = useCallback(
		(data?: FormDataType, isQuiet = true) => {
			if (data) {
				baseFieldValuesRef.current = data
				runFormValidation(isQuiet)
				// TODO: Do I also update all the inputs to have these reset values?????
			} else if (form.current) {
				removeValidationAnnotation()
				setShouldReset(true)
			}
		},
		[removeValidationAnnotation, runFormValidation],
	)

	// make sure fields are set to default before reassigning baseFieldValues
	useEffect(() => {
		if (shouldReset) {
			baseFieldValuesRef.current = serialize(new FormData(form.current), schemaReference)
			runFormValidation(true)
			setShouldReset(false)
		}
	}, [shouldReset, schemaReference, runFormValidation])

	/**
	 * This is bound to the form directly, and fired here. On blur of any field within the form, it will re-run validation
	 * and either clean-up the error or present the validation error.
	 */
	const handleFieldFocusout = useCallback(
		(event: React.FocusEvent<HTMLFormElement>): void => {
			const field = event.target
			/**
			 * *-combobox is a postfix added for <Select> components. To support the change events on these components,
			 * we need to truncate the name and target the hidden input.
			 */
			const fieldName = field.name?.includes('-combobox') ? field.name.replace('-combobox', '') : field.name
			if (!schemaReference[fieldName]) return
			try {
				// Validate field that just lost focus, set/remove error on result
				const fieldValidation = validateZodSchema({
					schema: validationSchema.shape[fieldName],
					data: field.value,
				})
				const isFieldValid = fieldValidation.success
				field.setAttribute('aria-invalid', String(!isFieldValid))

				if (!isFieldValid && fieldValidation?.error?.issues?.length > 0) {
					setFieldError(field, fieldValidation.error.issues[0].message)
				}
			} catch {
				logger.warn('invalid validation')
			}
		},
		[validationSchema, schemaReference],
	)

	/**
	 * Initially fired when the current attached form is submitted. It will run validation, where any messages will
	 * be thrown, and it will then call the supplied `onSubmit()` function with the validated result as the parameters
	 * to be acted on in the parent component.
	 */
	const handleFormSubmit = useCallback(
		function handleFormSubmit(event?: React.FormEvent<HTMLFormElement>): void {
			event?.preventDefault()
			const { invalidFields, validation } = runFormValidation()
			if (Object.keys(invalidFields).length > 0) {
				setLiveValidation(true)
			}
			onSubmit(
				{
					validated: Object.keys(invalidFields).length === 0,
					validityState: invalidFields,
					values: validation.data,
				} as ValidatedFormSubmit<FormDataType>,
				form.current as HTMLFormElement,
				event,
			)
		},
		[onSubmit, runFormValidation],
	)

	/**
	 * This function calls runFormValidation but is tied to a state that is only set to true
	 * when the form is submitted and has failed validation. This is to allow the form to be
	 * validated live as the user types, but only after the first submit attempt fails validation.
	 * The component importing this is responsible for setting this method to an onHandler.
	 */
	const handleLiveValidation = useCallback(
		(isQuiet?: boolean | undefined): void => {
			if (liveValidation) {
				const { isValid } = runFormValidation(isQuiet)
				if (isValid) setLiveValidation(false)
			}
		},
		[liveValidation, runFormValidation],
	)

	const handleFormChange = useDebouncedCallback((): void => {
		runFormValidation(true)
	}, 200)

	/**
	 * Function that accesses if the current base data of the form differs from the current data values.
	 * This could be a potentially expensive operation, this is why it's in a callback and not resolved with each
	 * field update.
	 */
	const getIsDirty = useCallback(
		function getIsDirty(): boolean {
			if (!form.current) {
				return false
			}
			return !isEqual(baseFieldValuesRef.current, serialize(new FormData(form.current), schemaReference))
		},
		[schemaReference],
	)

	const bindFormValidation = useCallback(
		(reactRef: HTMLFormElement | null): void => {
			if (reactRef) {
				form.current = reactRef
				form.current?.setAttribute('noValidate', 'true')
				form.current?.addEventListener('submit', handleFormSubmit as unknown as EventListener)
				form.current?.addEventListener('input', handleFormChange as unknown as EventListener)
			} else {
				form.current?.removeEventListener('submit', handleFormSubmit as unknown as EventListener)
				form.current?.removeEventListener('input', handleFormChange as unknown as EventListener)
			}
		},
		[handleFormChange, handleFormSubmit],
	)

	const getFormData = useCallback((): FormDataType => {
		return serialize(new FormData(form.current), schemaReference) as FormDataType
	}, [schemaReference])

	return useMemo(
		() =>
			({
				reset,
				getIsDirty,
				handleFieldFocusout,
				validState,
				getFormData,
				bindFormValidation: exposeRefTo(polyfillFormErrors(), bindFormValidation),
				runFormValidation,
				submitForm: handleFormSubmit,
				handleLiveValidation,
			} as UseFormValidation<FormDataType>),
		[
			reset,
			getIsDirty,
			handleFieldFocusout,
			validState,
			getFormData,
			bindFormValidation,
			runFormValidation,
			handleFormSubmit,
			handleLiveValidation,
		],
	)
}

/**
 * Serializes the FormData and aggregates any like-named checkbox inputs into a single collection.
 */
function serialize(data: FormData, schemaReference: Record<string, SchemaTypeReferenceOptions>) {
	const obj = {}
	data.forEach((value, key) => {
		// If current key already has a value in the returned object, add/create an array for the values
		if (obj[key] !== undefined) {
			if (!Array.isArray(obj[key])) {
				obj[key] = [obj[key]]
			}
			obj[key].push(value)
		} else {
			// On first instance, add key/value to object
			obj[key] = schemaReference[key] === SchemaTypeReferenceOptions.ARRAY ? [value] : value
		}
	})

	/** doing some array-type cleanup to return an empty array if it is defined in the schema */
	const missingFieldsFromSerializedObj = Object.keys(schemaReference).filter((key) => !Object.keys(obj).includes(key))
	missingFieldsFromSerializedObj.forEach((key) => {
		if (schemaReference[key] === SchemaTypeReferenceOptions.ARRAY) {
			obj[key] = []
		}
	})
	return obj
}

export function setFieldError(element: HTMLElement, message: string): void {
	const error = document.getElementById(element.getAttribute('aria-describedby') as string)
	if (!error) return
	error.textContent = message
}

function getContainsEmptyFields(fields?: z.ZodError): boolean {
	if (!fields) return false
	return fields.issues.some((field) => field.code === 'too_small')
}
