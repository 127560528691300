export const phoneRegex = /^\d{3}-\d{3}-\d{4}$/
export const emailRegex =
	/^([a-zA-Z0-9_+\-\\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
const nonLatinChars = /[\u0250-\ue007]/g
// Regex from sfcc input
const insecureRegex =
	/(<body)|(<iframe)|(\\.js$)|(javascript)|(<script>)|(<\/script>)|(<script\/>)|(<script)|(script>)|(script\/>)|(cookie)|(<img)|(<)|(>)|(vbscript)|(msgbox)|(alert\(\))|<.*?>/
// StackOverflow source of regex https://stackoverflow.com/a/41543705
const emojiRegex =
	/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/
const regexToCheckEmojiNonLatinChars =
	/^(?!.*(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]|[\u0250-\ue007]))/
export const validatePhoneNumber = (input: string) => phoneRegex.test(input)
export const validateEmail = (input: string) => emailRegex.test(input)
export const hasEmoji = (input: string) => emojiRegex.test(input)
export const hasInsecureSymbols = (input: string) => insecureRegex.test(input)
export const validateGiftOption = (input: string) =>
	!hasInsecureSymbols(input) && regexToCheckEmojiNonLatinChars.test(input)

// Clear space at the first position, replace multiple spaces with space, and clear non-Latin characters
export const clearDeprecatedCharactersInInput = (input: string) =>
	input.replace(nonLatinChars, '').trimStart().replace(/\s+/g, ' ')

export const validateMaxLength = (el: HTMLInputElement | HTMLSelectElement, allowMaxLength = true) =>
	!('maxLength' in el && (allowMaxLength ? el.value.length > el.maxLength : el.value.length >= el.maxLength))

// lengthError: 8 or more characters required
// casingError: 1 uppercase & 1 lowercase letter required
// numberError: 1 number required
// specialCharacterError: 1 of these special characters @$!%*?& required
export const validatePassword = (password: string, email?: string) => {
	const lengthError = password.length < 8
	const casingError = !/.*[a-z].*/.test(password) || !/.*[A-Z].*/.test(password)
	const numberError = !/.*\d.*/.test(password)
	// this regex has to validate the password with all the rules combined
	// to ensure that it tests for only the allowed characters. anything outside of
	// what is specified in the rules should throw validation error to user
	const specialCharacterError = !/.*[@$!%*?&].*/.test(password)
	const isContainEmail = !!email && password.toLowerCase().includes(email.toLowerCase())

	return {
		isContainEmail,
		lengthError,
		casingError,
		numberError,
		specialCharacterError,
		invalid: lengthError || casingError || numberError || specialCharacterError || isContainEmail,
	}
}

const patterns = {
	address: /^([^_<]){1,35}$/,
	city: {
		us: /^([^0-9<]){1,100}$/,
		ca: /^([^_<]){1,100}$/,
		at: /^([^_<]){1,100}$/,
		be: /^([^_<]){1,100}$/,
		dk: /^([^_<]){1,100}$/,
		fr: /^([^_<]){1,100}$/,
		de: /^([^_<]){1,100}$/,
		gb: /^([^_<]){1,100}$/,
		ie: /^([^_<]){1,100}$/,
		it: /^([^_<]){1,100}$/,
		nl: /^([^_<]){1,100}$/,
		es: /^([^_<]){1,100}$/,
		se: /^([^_<]){1,100}$/,
		default: /^([^_<]){1,100}$/,
	},
	postalCode: {
		us: /^\d{5}$|^\d{5}-\d{4}|\d{5,9}\s*$/,
		ca: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( ){1}\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i,
		at: /^\d{4}$/,
		be: /^\d{4}$/,
		dk: /^\d{4}$/,
		fr: /^\d{5}$/,
		de: /^\d{5}$/,
		gb: /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i,
		ie: /(?:^[AC-FHKNPRTV-Y][0-9]{2}|D6W)[ -]?[0-9AC-FHKNPRTV-Y]{4}$/i,
		it: /^\d{5}$/,
		nl: /^(?:NL-)?(\d{4})\s*([A-Z]{2})$/i,
		es: /^\d{5}$/,
		se: /^\d{3}\s*\d{2}$/,
		default: /^\d{5}$/,
	},
}

export const getAddressValidationPatterns = (countryCode: string /* locale: string */) => {
	// TODO: Remove fallback to default locale when all locales are supported
	const cityReg = patterns.city[countryCode] ?? patterns.city.default
	const postalReg = patterns.postalCode[countryCode] ?? patterns.postalCode.default
	return {
		address: new RegExp(`(?=^${regexToCheckEmojiNonLatinChars.source})(${patterns.address.source}).*`),
		city: new RegExp(`(?=^${regexToCheckEmojiNonLatinChars.source})(${cityReg.source}).*`),
		postalCode: new RegExp(`(?=^${regexToCheckEmojiNonLatinChars.source})(${postalReg.source}).*`),
	}
}
